import { createActor, fromCallback } from "xstate";

import type { ActorRefFrom, MachineImplementationsFrom } from "xstate";

import {
	MUTATE_EVENT_NAME,
	mutationObserver,
} from "$applib/actions/mutation-observer";

import { dropdownMachine } from "./machine";
import { EventName } from "./machine/events";

import type { DropdownMachine } from "./machine";

type DropdownActor = ActorRefFrom<DropdownMachine>;

const DROPDOWN_SELECTOR = "[data-hs-dropdown]";
const MENU_SELECTOR = "[data-hs-dropdown-menu]";

const options: Partial<MachineImplementationsFrom<DropdownMachine>> = {
	actions: {
		expandMenu({ context: { toggle, menuEl } }) {
			for (const el of [toggle, menuEl]) {
				el.setAttribute("aria-expanded", "true");
			}

			if (!menuEl.contains(document.activeElement)) {
				const child = menuEl.querySelector<HTMLElement>("[role=menuitem]");

				if (child) {
					child.focus();
				}
			}
		},

		collapseMenu({ context: { menuEl, toggle } }) {
			for (const el of [toggle, menuEl]) {
				el.setAttribute("aria-expanded", "false");
			}
		},
	},
	actors: {
		ioExpanded: fromCallback(({ sendBack, input: { toggle, menuEl } }) => {
			const handlers = [
				{
					target: document,
					event: "click",
					handler: function handleDocumentClick(event: Event) {
						sendBack({
							type: EventName.DocumentClick,
							params: { event: event as MouseEvent },
						});
					},
				},
				{
					target: toggle,
					event: "keyup",
					handler: function handleKeyUp(event: Event) {
						sendBack({
							type: EventName.KeyboardPress,
							params: { event: event as KeyboardEvent },
						});
					},
				},
				{
					target: menuEl,
					event: "dropdownmenu:close",
					handler: function handleCloseEvent() {
						sendBack({ type: EventName.RxClose });
					},
				},
				{
					target: menuEl,
					event: "keyup",
					handler: function handleKeyUp(event: Event) {
						sendBack({
							type: EventName.KeyboardPress,
							params: { event: event as KeyboardEvent },
						});
					},
				},
			];

			for (const { target, event, handler } of handlers) {
				target.addEventListener(event, handler);
			}

			return function destroy() {
				for (const { target, event, handler } of handlers) {
					target.removeEventListener(event, handler);
				}
			};
		}),
		ioGlobal: fromCallback(({ sendBack, input: { toggle } }) => {
			const handlers = [
				{
					target: toggle,
					event: "click",
					handler: function handleToggleClick() {
						sendBack({ type: EventName.ClickDropdownButton });
					},
				},
				{
					target: document,
					event: "focusin",
					handler: function handleFocus(event: Event) {
						sendBack({
							type: EventName.DocumentFocus,
							params: { event: event as FocusEvent },
						});
					},
				},
			];

			for (const { target, event, handler } of handlers) {
				target.addEventListener(event, handler);
			}

			return function destroy() {
				for (const { target, event, handler } of handlers) {
					target.removeEventListener(event, handler);
				}
			};
		}),
	},
	guards: {
		isOutsideClick: ({ context: { toggle, menuEl } }, params) => {
			const { event: domEvent } = params;
			const target = domEvent.target as HTMLElement;
			const isToggle = toggle.contains(target);
			const isMenu = menuEl.contains(target);

			return !(isToggle || isMenu);
		},

		isInsideFocus: ({ context: { menuEl } }, params) => {
			const { event: domEvent } = params;
			const target = domEvent.target as HTMLElement;

			return menuEl.contains(target);
		},

		isOutsideFocus: ({ context: { menuEl } }, params) => {
			const { event: domEvent } = params;
			const target = domEvent.target as HTMLElement;

			return !menuEl.contains(target);
		},

		isCloseKey: (_, params) => {
			const { event: domEvent } = params;
			const { key } = domEvent;

			return key === "Escape";
		},
	},
};

function dropdownsManagerFactory() {
	const services: Map<
		HTMLElement,
		{ service: DropdownActor; destroy(): void }
	> = new Map();

	function init() {
		const { body } = document;

		mutationObserver(body, { childList: true, subtree: true });
		body.addEventListener(MUTATE_EVENT_NAME, cleanDropdowns);
	}

	function selectDropdowns(nodes: Node[]): HTMLElement[] {
		return nodes
			.filter((x) => x instanceof HTMLElement)
			.map((x) =>
				(x as HTMLElement).querySelectorAll<HTMLElement>(DROPDOWN_SELECTOR),
			)
			.flatMap((dropdownEls) => Array.from(dropdownEls));
	}

	function cleanDropdowns(event: Event) {
		const customEvent = event as CustomEvent<{ mutations: MutationRecord[] }>;
		const { mutations } = customEvent.detail;
		const addedDropdowns = mutations
			.map((xs) => Array.from(xs.addedNodes))
			.flatMap(selectDropdowns);
		const removedDropdowns = mutations
			.map((xs) => Array.from(xs.removedNodes))
			.flatMap(selectDropdowns);

		removedDropdowns.map(removeDropdown);
		addedDropdowns.map(addDropdown);
	}

	function addDropdown(element: HTMLElement) {
		const menuEl = element.querySelector<HTMLElement>(MENU_SELECTOR);
		const toggleId = menuEl ? menuEl.getAttribute("aria-labelledby") : null;
		const toggle = toggleId
			? (document.querySelector(`#${toggleId}`) as HTMLButtonElement)
			: null;

		if (!toggle || !menuEl) {
			return;
		}

		const service = createActor(dropdownMachine.provide(options), {
			input: { toggle, menuEl },
		});

		service.start();

		element.setAttribute("data-hs-dropdown-state", "initialized");

		const existingDropdown = services.get(element);

		if (existingDropdown) {
			existingDropdown.destroy();
		}

		services.set(element, {
			service,
			destroy() {
				service.stop();
				services.delete(element);
			},
		});
	}

	function removeDropdown(element: HTMLElement) {
		const service = services.get(element);

		if (service) {
			service.destroy();
		}
	}

	init();

	return {
		addDropdown,
	};
}

const dropdownManager = dropdownsManagerFactory();

document.addEventListener("hs:dropdown:init", (event) => {
	dropdownManager.addDropdown(event.target as HTMLDivElement);
});
