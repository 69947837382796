import { fromCallback, setup } from "xstate";

import type { ActorRefFrom, EventObject, MachineContext } from "xstate";

import { noop } from "$applib/utils/functions";
import { EventName } from "./events";

interface DropdownContext extends MachineContext {
	toggle: HTMLButtonElement;
	menuEl: HTMLElement;
}

type DropdownStateMachine = typeof dropdownMachine;

export type DropdownActor = ActorRefFrom<DropdownStateMachine>;

const dropdownMachine = setup({
	types: {} as {
		context: DropdownContext;
		events:
			| { type: EventName.ClickDropdownButton }
			| { type: EventName.DocumentClick; params: { event: MouseEvent } }
			| { type: EventName.DocumentFocus; params: { event: FocusEvent } }
			| { type: EventName.KeyboardPress; params: { event: KeyboardEvent } }
			| { type: EventName.RxClose };
		input: DropdownContext;
	},
	actions: {
		collapseMenu: noop,
		expandMenu: noop,
	},
	actors: {
		ioGlobal: fromCallback<EventObject, DropdownContext>(() => {
			throw new Error("Not implemented");
		}),
		ioExpanded: fromCallback<EventObject, DropdownContext>(() => {
			throw new Error("Not implemented");
		}),
	},
	guards: {
		isCloseKey: (_, _params: { event: KeyboardEvent }) => false,
		isInsideFocus: (_, _params: { event: FocusEvent }) => false,
		isOutsideClick: (_, _params: { event: MouseEvent }) => false,
		isOutsideFocus: (_, _params: { event: FocusEvent }) => false,
	},
}).createMachine({
	id: "dropdown",
	context: ({ input: { toggle, menuEl } }) => ({ toggle, menuEl }),
	initial: "collapsed",
	invoke: {
		id: "ioGlobal",
		src: "ioGlobal",
		input: ({ context: { toggle, menuEl } }) => ({ toggle, menuEl }),
	},

	states: {
		collapsed: {
			entry: ["collapseMenu"],
			on: {
				[EventName.ClickDropdownButton]: { target: "expanded" },
				[EventName.DocumentFocus]: {
					guard: {
						type: "isInsideFocus",
						params: ({ event }) => ({ event: event.params.event }),
					},
					target: "expanded",
				},
			},
		},
		expanded: {
			entry: [{ type: "expandMenu" }],
			invoke: {
				id: "ioExpanded",
				src: "ioExpanded",
				input: ({ context: { toggle, menuEl } }) => ({ toggle, menuEl }),
			},
			on: {
				[EventName.ClickDropdownButton]: { target: "collapsed" },
				[EventName.DocumentClick]: {
					guard: {
						type: "isOutsideClick",
						params: ({ event: { params } }) => ({ event: params.event }),
					},
					target: "collapsed",
				},
				[EventName.DocumentFocus]: {
					guard: {
						type: "isOutsideFocus",
						params: ({ event: { params } }) => ({ event: params.event }),
					},
					target: "collapsed",
				},
				[EventName.KeyboardPress]: {
					guard: {
						type: "isCloseKey",
						params: ({ event: { params } }) => ({ event: params.event }),
					},
					target: "collapsed",
				},
				[EventName.RxClose]: { target: "collapsed" },
			},
		},
	},
});

export type DropdownMachine = typeof dropdownMachine;

export { dropdownMachine };
