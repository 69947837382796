/**
 * resetEventHandlersFactor.
 *
 * removes any existing event handlers for the createAppointment function,
 * and then rebinds them
 *
 * Useful for when the DOM changes, and new elements are added
 *
 * @param {string} className
 */
function resetEventHandlersFactory({
	className,
	eventType,
	handler,
}: {
	className: string;
	eventType: Event["type"];
	handler: (event: Event) => void;
}) {
	return () => {
		const elements = Array.from(document.getElementsByClassName(className));

		elements.map((element) => {
			element.removeEventListener(eventType, handler);
			element.addEventListener(eventType, handler);
		});
	};
}

export { resetEventHandlersFactory };
